import React, { ReactNode, CSSProperties } from "react";

import { ButtonVariants } from "./Button.types";
import { buttonUseStyles } from "./Button.styles";
import { Link } from "react-router-dom";
import Spinner from "reusableComponents/SpinnerLoading/SpinnerLoading";

export interface ButtonProps {
  variant: ButtonVariants;
  disabled?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  style?: CSSProperties;
  type?: any;
  className?: any;
  size?: "inherit" | "small";
  link?: string;
  isLoading?: boolean;
}

const getButtonStylesByVariant = (
  variant: ButtonVariants,
  disabled: boolean,
  size: string
) => {
  const buttonStyles = buttonUseStyles({ disabled, size });
  switch (variant) {
    case ButtonVariants.Primary:
      return buttonStyles.primaryButton;
    case ButtonVariants.Secondary:
      return buttonStyles.secondaryButton;
    case ButtonVariants.Outlined:
      return buttonStyles.outlinedButton;
    default:
      return buttonStyles.primaryButton;
  }
};

export const Button: React.FC<any> = ({
  variant,
  disabled = false,
  handleClick,
  children,
  style,
  type,
  className,
  size = "inherit",
  link,
  isLoading,
}: ButtonProps) => {
  const wholeClassName = `${className} ${getButtonStylesByVariant(
    variant,
    !!disabled,
    size
  )}`;
  return !link ? (
    <button
      onClick={handleClick}
      style={style}
      className={wholeClassName}
      disabled={disabled}
      type={type}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  ) : (
    <Link style={style} className={wholeClassName} type={type} to={`${link}`}>
      {children}
    </Link>
  );
};
