export const BreakPointSize = {
  Mobile: 500,
  SmallTablet: 768,
  Tablet: 992,
  Laptop: 1200,
  LargeScreen: 1920,
};

export const Breakpoints = {
  LargeScreen: `@media screen and (max-width: ${BreakPointSize.LargeScreen}px)`,
  Laptop: `@media screen and (max-width: ${BreakPointSize.Laptop}px)`,
  Tablet: `@media screen and (max-width: ${BreakPointSize.Tablet}px)`,
  Mobile: `@media screen and (max-width: ${BreakPointSize.Mobile}px)`,
};
