import { Breakpoints } from "constants/styles.constants";
import { createUseStyles } from "react-jss";

export const waveFormUseStyles = createUseStyles(() => ({
  playIcon: {
    borderRadius: "50%",
    cursor: "pointer",
    //transition: "boxShadow 0.5s ease",
    "&>img": {
      display: "block",
      //boxShadow: "0px 0px 0px 3.498460054397583px #C6F6F0",
      borderRadius: "50%",
    },
    "&:focus": {
      outline: "none",
    },
  },
  skeleton: {
    width: "280px",
  },
  listeningDuration: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#697182",
    width: "50px",
  },

  //responsiveness

  [Breakpoints.Laptop]: {
    skeleton: {
      width: "130px",
    },
  },
}));
