import { ROUTES } from "constants/routes";
import { lazy } from "react";

export const authRoutes = [
  {
    path: ROUTES.HOME,
    component: lazy(() => import("pages/Home/HomePage")),
  },
  {
    path: ROUTES.ABOUT_PROJECT,
    component: lazy(() => import("pages/AboutProject/AboutProject")),
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    component: lazy(() => import("pages/PrivacyPolicy/PrivacyPolicy")),
  },
  {
    path: ROUTES.TOPICS,
    component: lazy(() => import("pages/Topics/Topics")),
  },
];