import { useRef, useState, useEffect, useContext } from "react";
import play from "assets/Icons/play.svg";
import pause from "assets/Icons/pause.svg";
import volumeOn from "assets/Icons/mute.svg";
import volumeOff from "assets/Icons/unmute.svg";
import { useWavesurfer } from "@wavesurfer/react";
import { waveFormUseStyles } from "reusableComponents/WaveForm/WaveForm.styles";
import { formatListeningDuration } from "helpers/formatSeconds";
import Skeleton from "react-loading-skeleton";
import { playAudio, stopAudio } from "helpers/audioPlayPause";
import { PlayerContext } from "context/audioPlayerContext";

const Waveform = ({
  audio,
  listeningDuration,
  autoPlay = false,
}: {
  audio: any;
  listeningDuration: number;
  autoPlay?: boolean;
}) => {
  const styles = waveFormUseStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { playerOptions, setPlayerOptions } = useContext<any>(PlayerContext);

  const { isPlaying } = playerOptions;

  const { isReady, wavesurfer, currentTime } = useWavesurfer({
    container: containerRef,
    waveColor: "#DDDEDE",
    progressColor: "#537FC1",
    barWidth: 4,
    height: 32,
    barRadius: 28,
    cursorColor: "#537FC1",
    url: audio,
    normalize: true,
  });

  useEffect(() => {
    if (isReady !== undefined) {
      setPlayerOptions((prevOptions: any) => ({
        ...prevOptions,
        isReady: isReady,
      }));
    }
    //eslint-disable-next-line
  }, [isReady]);

  useEffect(() => {
    if (wavesurfer) {
      const handlePlay = () => playAudio(wavesurfer);
      const handlePause = () => stopAudio(wavesurfer);
      const handleFinish = () => stopAudio(wavesurfer);
      const handleError = (err: any) => setError(`${err}`);

      wavesurfer.on("play", handlePlay);
      wavesurfer.on("pause", handlePause);
      wavesurfer.on("finish", handleFinish);
      wavesurfer.on("error", handleError);

      return () => {
        wavesurfer.un("play", handlePlay);
        wavesurfer.un("pause", handlePause);
        wavesurfer.un("finish", handleFinish);
        wavesurfer.un("error", handleError);
        wavesurfer.destroy();
      };
    }
  }, [wavesurfer]);

  useEffect(() => {
    if (wavesurfer) {
      if (isPlaying && !wavesurfer.isPlaying() && autoPlay) {
        wavesurfer.play();
      } else if (!isPlaying && wavesurfer.isPlaying()) {
        wavesurfer.pause();
      }
    }
  }, [isPlaying, wavesurfer, autoPlay]);

  if (error) {
    return <div />;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      {!!isReady ? (
        <div
          className={styles.playIcon}
          onClick={() => {
            if (wavesurfer) {
              wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play();
            }
          }}
        >
          <img src={wavesurfer?.isPlaying() ? pause : play} alt="play/pause" />
        </div>
      ) : (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Skeleton height={32} width={32} circle />
          <Skeleton className={styles.skeleton} height={30} />
          <Skeleton height={30} width={60} />
        </div>
      )}
      <div ref={containerRef} style={{ width: "50%" }} />
      {!!isReady && (
        <>
          <div className={styles.listeningDuration}>
            {formatListeningDuration(listeningDuration, currentTime)}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              if (wavesurfer) {
                wavesurfer.setVolume(isMuted ? 1 : 0);
                setIsMuted(!isMuted);
              }
            }}
          >
            <img src={isMuted ? volumeOff : volumeOn} alt="mute" />
          </div>
        </>
      )}
    </div>
  );
};

export default Waveform;
