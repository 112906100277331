import { variables } from "variables";

const { REACT_APP_REDIRECT_URL } = variables;

export const ROUTES = {
  SIGN_IN: REACT_APP_REDIRECT_URL,
  SIGN_UP: `${REACT_APP_REDIRECT_URL}sign-up`,
  HOME: "/",
  ABOUT_PROJECT: "/about-project",
  PRIVACY_POLICY: "/about-project/privacy-policy",
  TOPICS: "/topics/:topic",
  NOT_FOUND: "error/404",
};
