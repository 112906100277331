import { Breakpoints } from "constants/styles.constants";
import { createUseStyles } from "react-jss";

interface INewsStylesProps {
  index: number;
  topicsTodayNews?: boolean;
  openSummaryIndex?: number | null;
}
export const latestNewsItemUseStyles = createUseStyles(() => ({
  playIcon: {
    borderRadius: "50%",
    position: "absolute",
    top: "215px",
    right: "20px",
    cursor: "pointer",
    "&>img": {
      display: "block",
      borderRadius: "50%",
    },
    "&:focus": {
      outline: "none",
    },
  },

  newsItemImageWrap: {
    maxWidth: "320px",
    minWidth: "320px",
  },
  newsImage: {
    width: "100%",
    height: "240px",
    borderRadius: "12px",
    objectFit: "cover",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#171D29",
    height: "auto",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": ({ topicsTodayNews }: INewsStylesProps) =>
      !topicsTodayNews ? "1" : 2,
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
  },
  newsItemContainer: {
    maxWidth: "100%",
    padding: ({ index }: INewsStylesProps) =>
      index === 1 || index === 4
        ? "0 24px 24px 24px"
        : index === 0 || index === 3
        ? "0px 24px 0 0"
        : "0px 0 24px 24px",
    borderRight: ({ index }: INewsStylesProps) =>
      index !== 2 && index !== 5 ? "1px solid #E6E6EB" : "none",
    paddingBottom: ({ topicsTodayNews }: INewsStylesProps) =>
      topicsTodayNews ? "0" : "24px",
  },
  source: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#697182",
  },
  category: {
    padding: "7px 16px",
    fontSize: "18px",
    fontWeight: "500",
    color: "#4E5BA6",
    background: "#EDEFF6",
    borderRadius: "16px",
  },

  durationsContainer: {
    padding: "6px 16px",
    background: "#F2F3F4",
    borderRadius: "40px",
    "&>:first-child": {
      paddingRight: "12px",
      borderRight: "1px solid #E6E6EB",
    },
  },

  viewSummaryBtn: {
    fontSize: "20px",
    fontWeight: "500",
    color: "#537FC1",
  },
  moreNewsWrapper: {
    width: "100%",
    height: "auto",
  },

  summaryInfoMobile: {
    display: "none",
  },

  viewSummary: {
    cursor: "pointer",
    borderBottom: ({
      index,
      topicsTodayNews,
      openSummaryIndex,
    }: INewsStylesProps) =>
      index > 2 || topicsTodayNews || openSummaryIndex !== null
        ? "none"
        : "1px solid #E6E6EB",
    padding: ({ index, topicsTodayNews }: INewsStylesProps) =>
      index > 2 || topicsTodayNews ? "none" : "0 0px 24px",
  },

  durationValue: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#697182",
  },

  [Breakpoints.Tablet]: {
    viewSummary: {
      padding: "none ",
      border: "none",
    },
    moreNewsWrapper: {
      flexDirection: "column",
      "&>:div": {
        width: "100%",
      },
    },
    newsItemImageWrap: {
      minWidth: "100% !important",
    },
    newsItemContainer: {
      width: "100%",
      justifyContent: "space-between",
      padding: "0px 0px 24px 0px !important",
      border: "none !important",
    },
    summaryInfoMobile: {
      display: "block",
    },
  },
}));

export const summaryInfoUseStyles = createUseStyles(() => ({
  summaryContainer: {
    padding: "24px",
    background: "#F7F7F7",
    marginTop: "18px",
    borderRadius: "20px",
    marginBottom: "24px",
    "&:before": {
      content: "''",
      position: "absolute",
      transform: "translate(-50%) rotate(45deg)",
      width: "20px",
      height: "20px",
      backgroundColor: "#F7F7F7",
      top: "-10px",
      left: ({ index }: INewsStylesProps) => `${15 + 35 * (index % 3)}%`,
    },
  },
  linkToArticle: {
    padding: "8px 20px",
    borderRadius: "50px",
    border: "1px solid #E6E6EB",
    background: "#FFF",
    cursor: "pointer",

    "&>a": {
      color: "#697182",
      fontSize: "16px",
      fontWeight: "500",
      textDecoration: "none",
    },
    "&>img": {
      display: "block",
    },
  },

  gridColumnWrap: {
    gridColumn: "span 3",
  },

  publishedHours: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#A3A9B5",
  },
  summary: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#697182",
  },
}));

export const audioPlayerUseStyles = createUseStyles(() => ({
  audioPlayerWrapper: {
    maxWidth: "1472px",
    margin: "0 auto",
    width: "100%",
    position: "fixed",
    bottom: "0",
    boxSizing: "border-box",
    zIndex: "999",
  },
  audioPlayerContainer: {
    padding: "20px 32px 32px",
    borderRadius: "12px 12px 0px 0px",
    background: "#EBECEE",
    maxWidth: "544px",
    width: "100%",
    boxSizing: "border-box",
    position: "absolute",
    zIndex: "9999",
  },
  playIcon: {
    borderRadius: "50%",
    cursor: "pointer",
    "&>img": {
      display: "block",
      borderRadius: "50%",
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeIcon: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "&>img": {
      display: "block",
    },
  },
  title: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#171D29",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
  },
}));
