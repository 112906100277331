import { ReactComponent as SpinnerSvg } from "assets/Icons/spinner.svg";
import { useSpinnerStyles } from "./Spinner.style";

const Spinner = () => {
  const styles = useSpinnerStyles();
  return (
    <div className={styles.spinner}>
      <SpinnerSvg />
    </div>
  );
};

export default Spinner;
