import { Breakpoints } from "constants/styles.constants";
import { createUseStyles } from "react-jss";

interface ButtonStyleProps {
  disabled?: boolean;
  color?: string;
  size: string;
}

const ButtonSizes: any = {
  inherit: {
    fontSize: "18px",
    padding: "12px 24px",
  },
  small: {
    fontSize: "14px",
    padding: "10px 16px",
  },
};

export const buttonUseStyles = createUseStyles(() => ({
  primaryButton: {
    position: "relative",
    padding: ({ size }: ButtonStyleProps) => ButtonSizes[size]?.padding,
    background: ({ disabled }: ButtonStyleProps) =>
      disabled ? "#EAECF0" : "#537FC1",
    borderRadius: "8px",
    fontWeight: "500",
    fontSize: ({ size }: ButtonStyleProps) => ButtonSizes[size]?.fontSize,
    lineHeight: "24px",
    color: ({ disabled }: ButtonStyleProps) =>
      disabled ? "#C6CCD4" : "#FFFFFF",
    border: "none",
    cursor: ({ disabled }: ButtonStyleProps) =>
      disabled ? "initial" : "pointer",
    whiteSpace: "nowrap",
    "&:hover": {
      background: ({ disabled }: ButtonStyleProps) =>
        disabled ? "#EAECF0" : "#446BA6",
    },
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  secondaryButton: {
    padding: ({ size }: ButtonStyleProps) => ButtonSizes[size]?.padding,
    background: "#EAECF0",
    borderRadius: "8px",
    fontWeight: "500",
    fontSize: ({ size }: ButtonStyleProps) => ButtonSizes[size]?.fontSize,
    lineHeight: "24px",
    color: "#858C9B",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#EBEEF3",
      color: "#5D6269",
    },
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  outlinedButton: {
    padding: ({ size }: ButtonStyleProps) => ButtonSizes[size]?.padding,
    background: "#FFFFFF",
    border: ({ disabled }: ButtonStyleProps) =>
      disabled ? "1px solid #EAECF0" : "1px solid #537FC1",
    borderRadius: "8px",
    fontSize: ({ size }: ButtonStyleProps) => ButtonSizes[size]?.fontSize,
    fontWeight: "500",
    lineHeight: "24px",
    cursor: "pointer",
    color: ({ disabled }: ButtonStyleProps) =>
      disabled ? "#D0D5DD" : "#537FC1",
    "&:hover": {
      border: ({ disabled }: ButtonStyleProps) =>
        !disabled && "1px solid #446BA6",
      color: ({ disabled }: ButtonStyleProps) => !disabled && "#446BA6",
    },
  },

  textDecoration: "none",
  display: "felx",
  alignItems: "center",
  justifyContent: "center",

  //responsiveness

  [Breakpoints.Tablet]: {
    outlinedButton: {
      width: "100%",
      fontSize: "16px !important",
    },

    secondaryButton: {
      width: "100%",
      fontSize: "16px !important",
    },
    primaryButton: {
      width: "100%",
      fontSize: "16px !important",
    },
  },
}));
