import React, { createContext, useState, ReactNode, useRef } from "react";

import AudioPlayer from "pages/Home/components/LatestNews/LatestNewsItem/AudioPlayer";

interface IPlayeroptions {
  isOpen: boolean;
  audio: any;
  listeningDuration: number | null;
  title: string;
  index: number | null;
  isPlaying: boolean;
  isReady: boolean;
}

export interface PlayerContextType {
  playerOptions: IPlayeroptions;
  setPlayerOptions: React.Dispatch<React.SetStateAction<IPlayeroptions>>;
  currentAudioInstance: React.MutableRefObject<any>;
}

const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

interface PlayerProviderProps {
  children: ReactNode;
}

const PlayerProvider: React.FC<PlayerProviderProps> = ({ children }) => {
  const [playerOptions, setPlayerOptions] = useState<IPlayeroptions>({
    isOpen: false,
    audio: null,
    listeningDuration: null,
    title: "",
    index: null,
    isPlaying: false,
    isReady: true,
  });

  const currentAudioInstance = useRef<any>(null);

  return (
    <PlayerContext.Provider
      value={{ playerOptions, setPlayerOptions, currentAudioInstance }}
    >
      {children}
      {playerOptions.isOpen && (
        <AudioPlayer
          playerOptions={playerOptions}
          setPlayerOptions={setPlayerOptions}
          currentAudioInstance={currentAudioInstance}
        />
      )}
    </PlayerContext.Provider>
  );
};

export { PlayerProvider, PlayerContext };
