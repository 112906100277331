interface AudioInstance {
  play(): void;
  pause(): void;
}
let currentAudio: AudioInstance | null = null;

export const playAudio = (audioInstance: AudioInstance) => {
  if (currentAudio && currentAudio !== audioInstance) {
    currentAudio.pause();
  }
  currentAudio = audioInstance;
};

export const stopAudio = (audioInstance: AudioInstance) => {
  if (currentAudio === audioInstance) {
    currentAudio = null;
  }
};
