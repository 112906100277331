import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

import { Flex } from "reusableComponents/Flex/Flex";
import { footerUseStyles } from "sections/Footer/Footer.styles";

import hottakeAiLogo from "assets/Icons/hottakeAiLogo.svg";
import { ReactComponent as HackTechLogo } from "assets/Icons/hacktech-logo.svg";

const Footer = ({ linkStyle }: { linkStyle?: any }) => {
  const styles = footerUseStyles();
  const navigate = useNavigate();
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div style={{ background: "#F2F3F4" }}>
      <Flex direction="column" gap="24px" className={styles.wrapper}>
        <img src={hottakeAiLogo} alt="" />
        <div className={styles.divider}></div>
        <Flex
          justifyContent="space-between"
          width="100%"
          className={styles.footerContainer}
        >
          <Flex gap="10px">
            <a
              href="https://gohacktech.com/"
              target="_blank"
              rel="noreferrer"
              className={styles.privacyPolicyLink}
            >
              Powered by{" "}
            </a>

            <HackTechLogo />
          </Flex>
          <Flex direction="column" gap="12px" alignItems="flex-end">
            <a
              href="privacy-policy"
              onClick={() => navigate(ROUTES.PRIVACY_POLICY)}
              className={styles.privacyPolicyLink}
              style={
                window.location.pathname === ROUTES.PRIVACY_POLICY
                  ? linkStyle
                  : {}
              }
            >
              Privacy Policy
            </a>

            <span style={{ color: "#858C9B" }}>
              © {year} All Rights Reserved
            </span>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default Footer;
