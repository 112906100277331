import { CSSProperties, FC } from "react";
import { ComponentWithChildren } from "../../types/component.types";
import { flexUsestyles } from "reusableComponents/Flex/Flex.styles";

interface IFlexProps extends ComponentWithChildren {
  children?: any;
  direction?: "row" | "column";
  alignItems?:
    | "stretch"
    | "center"
    | "flex-start"
    | "flex-end"
    | "baseline"
    | "initial"
    | "inherit";
  justifyContent?:
    | "center"
    | "flex-start"
    | "space-between"
    | "flex-end"
    | "space-around";
  flexWrap?: "nowrap" | "wrap";
  gap?: string;
  width?: string;
  refValue?: any;
  style?: CSSProperties;
  className?: string;
  onClick?: (e?: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Flex: FC<IFlexProps> = ({
  children,
  direction = "row",
  justifyContent = "flex-start",
  alignItems = "flex-start",
  flexWrap = "nowrap",
  style = {},
  className = "",
  refValue,
  gap = "",
  width = "auto",
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  const styles = flexUsestyles({
    gap,
    flexDirection: direction,
    alignItems,
    justifyContent,
    flexWrap,
    width,
  });

  const wholeClassName = `${className} ${styles.flex}`;
  return (
    <div
      ref={refValue}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={wholeClassName}
      style={style}
    >
      {children}
    </div>
  );
};
