import { Breakpoints } from "constants/styles.constants";
import { createUseStyles } from "react-jss";

export const PagesWrapperUseStyles = createUseStyles(() => ({
  PagesWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
    gap: "120px",

    "& > not-child(2)": {
      gap: "0 !important",
    },

    "& > div > :first-child": {
      maxWidth: "1472px",
      margin: "0 auto",
    },
  },

  //responsiveness

  [Breakpoints.LargeScreen]: {
    PagesWrapper: {
      "& > div > :first-child": {
        width: "calc(100% - 140px) !important",
        margin: "0 auto",
      },
    },
  },

  [Breakpoints.Tablet]: {
    PagesWrapper: {
      gap: "80px",
      "& > div > :first-child": {
        width: "calc(100% - 120px) !important",
        margin: "0 auto",
      },
    },
  },

  [Breakpoints.Mobile]: {
    PagesWrapper: {
      "& > div > :first-child": {
        width: "calc(100% - 40px) !important",
        margin: "0 auto",
      },
    },
  },
}));
