import Header from "sections/Header/Header";
import AppLayout from "./AppLayout";
import Footer from "sections/Footer/Footer";

import { PagesWrapperUseStyles } from "./PagesWrapper.styles";

const PagesWrapper = (): JSX.Element => {
  const styles = PagesWrapperUseStyles();
  return (
    <>
      <Header />
      <div className={styles.PagesWrapper}>
        <AppLayout />
      </div>
      <Footer />
    </>
  );
};

export default PagesWrapper;
