import { BrowserRouter as Router } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";

import { PlayerProvider } from "context/audioPlayerContext";
import PagesWrapper from "pages/PagesWrapper";
import ScrollToTop from "reusableComponents/ScrollToTop/ScrollToTop";

import "react-loading-skeleton/dist/skeleton.css";

const App = (): React.ReactElement => {
  return (
    <div className="App">
      <SkeletonTheme baseColor="#E8E6E6">
        <Router>
          <ScrollToTop />
          <PlayerProvider>
            <PagesWrapper />
          </PlayerProvider>
        </Router>
      </SkeletonTheme>
    </div>
  );
};

export default App;
