import { createUseStyles } from 'react-jss';

export const useSpinnerStyles = createUseStyles(()=> ({
  spinner: {
    display: 'flex',
    animationName: '$spin',
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    maxWidth: '15px',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));
