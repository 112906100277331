import { createUseStyles } from "react-jss";

interface FlexStyleProps {
  gap: string;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  flexWrap: string;
  width: string;
}

export const flexUsestyles = createUseStyles(() => ({
  flex: ({
    flexDirection,
    gap,
    alignItems,
    justifyContent,
    flexWrap,
    width,
  }: FlexStyleProps) => ({
    display: "flex",
    flexDirection,
    gap,
    alignItems,
    justifyContent,
    flexWrap,
    width,
  }),
}));
