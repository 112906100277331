import { Suspense } from "react";

import { authRoutes } from "./routes";
import SwitchRouter from "./SwitchRouter";

const AppLayout = () => (
  <Suspense fallback={<div style={{ height: "100vh" }}></div>}>
    <SwitchRouter routes={authRoutes} />
  </Suspense>
);

export default AppLayout;
