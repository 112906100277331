export const formatSeconds = (seconds: number) => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round((seconds % 3600) % 60);

  const formattedMinutes = minutes > 0 ? minutes + "min " : "";
  const formattedSeconds =
    remainingSeconds > 0 ? remainingSeconds + " sec" : "";

  return formattedMinutes + formattedSeconds;
};

export const formatListeningDuration = (
  listeningDuration: number,
  seconds: number
) => {
  let remainingSeconds = Math.round((listeningDuration - seconds) % 60);
  let remainingMinutes = Math.floor((listeningDuration - seconds) / 60);
  const formattedTime = [
    remainingMinutes.toString().padStart(2, "0"),
    remainingSeconds.toString().padStart(2, "0"),
  ].join(":");

  return formattedTime;
};
