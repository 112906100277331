import { FC, Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reusableComponents/Button/Button";
import { Flex } from "reusableComponents/Flex/Flex";
import { headerLinks } from "./constants";
import { ROUTES } from "constants/routes";
import { Topics } from "constants/onBoarding.constants";
import arrowDown from "assets/Icons/arrowDown.svg";
import arrowUp from "assets/Icons/arrowUp.svg";
import { headerMobailUseStyles } from "./Mobile.styles";

interface Props {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}

const GenerateMobileHeader: FC<Props> = ({ setIsMenuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isTopicsOpen, setIsTopicsOpen] = useState<boolean>(false);
  const topicFromRoute = decodeURIComponent(location.pathname.split("/")[2]);

  const handlelinkClick = (path: string) => {
    setIsMenuOpen(false);
    navigate(path);
  };
  const styles = headerMobailUseStyles();

  const generateHeaderLinks = () => {
    return headerLinks.map(({ name, path }) => {
      return (
        <Fragment key={name}>
          <Flex
            key={name}
            direction="column"
            onClick={() =>
              name === "Topics"
                ? setIsTopicsOpen(!isTopicsOpen)
                : handlelinkClick(path)
            }
            className={
              location.pathname === path ||
              (name === "Topics" && location.pathname.includes("topics"))
                ? styles.IsActive
                : styles.links
            }
          >
            {name === "Topics" ? (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <span>{name}</span>
                <img src={isTopicsOpen ? arrowUp : arrowDown} alt="" />
              </Flex>
            ) : (
              <span>{name}</span>
            )}
            {name === "Topics" && isTopicsOpen && (
              <Flex direction="column" className={styles.topicsContainer}>
                {Topics.map((topic: string) => (
                  <span
                    onClick={() => handlelinkClick(`/topics/${topic}`)}
                    className={
                      topicFromRoute.includes(topic)
                        ? styles.activeTopic
                        : styles.topic
                    }
                  >
                    {topic}
                  </span>
                ))}
              </Flex>
            )}
          </Flex>
        </Fragment>
      );
    });
  };

  return (
    <Fragment>
      <Flex
        className={styles.linksWrapperMobile}
        direction="column"
        alignItems="center"
      >
        {generateHeaderLinks()}
        <Flex
          className={styles.btnWrap}
          direction="column"
          gap="20px"
          alignItems="center"
        >
          <Button link={ROUTES.SIGN_UP}>Start free trial</Button>
          <a href={ROUTES.SIGN_IN}>Sign in</a>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default GenerateMobileHeader;
