import { Breakpoints } from "constants/styles.constants";
import { createUseStyles } from "react-jss";

export const headerUseStyles = createUseStyles(() => ({
  wrapper: {
    background: "#FFF",
    position: "sticky",
    top: "0",
    zIndex: "9999",
    borderBottom: "1px solid #E6E6EB",
  },
  headerWrapper: {
    margin: "0 auto",
    padding: "28px 0",
    gap: "120px",
    alignItems: "flex-end",
    maxWidth: "1472px",
  },
  linksContainer: {
    "&>span": {
      fontSize: "17px",
      fontWeight: "500",
      lineHeight: "30px",
      color: "#858C9B",
      cursor: "pointer",
      "&:hover": {
        color: "#5B626F",
      },
    },
    "&>a": {
      fontSize: "17px",
      fontWeight: "500",
      lineHeight: "30px",
      color: "#858C9B",
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        color: "#5B626F",
      },
    },
  },
  activeTab: {
    fontWeight: "700 !important",
    color: "#537FC1 !important",
  },
  linksContainerMobile: {
    "&>span": {
      fontSize: "17px",
      fontWeight: "500",
      lineHeight: "30px",
      color: "#858C9B",
    },
  },
  linksWrapper: {},
  menuIcon: {
    display: "none",
  },

  topicsSection: {
    width: "calc(100% - 140px)",
    margin: "0 auto",
    maxWidth: "1472px",
    position: "relative",
  },
  topicsWrapper: {
    maxHeight: "312px",
    padding: "32px 0",
    maxWidth: "100%",
    margin: "0 auto",
    top: "240px",
    zIndex: "1000",
  },
  topicsTitle: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#171D29",
  },
  topicsContainer: {
    maxWidth: "554px",
    height: "170px",
    "&>span": {
      fontSize: "20px",
      fontWeight: "400",
      color: "#697182",
      cursor: "pointer",
    },
  },
  closeIcon: {
    cursor: "pointer",
    "&>img": {
      display: "block",
    },
  },

  //responsiveness

  [Breakpoints.LargeScreen]: {
    headerWrapper: {
      width: "calc(100% - 140px)",
    },
  },

  [Breakpoints.Laptop]: {
    linksContainer: {
      "&>span ": {
        fontSize: "14px",
      },
      "&>a": {
        fontSize: "14px",
      },
    },
    headerWrapper: {
      gap: "40px !important",
      width: "calc(100% - 140px)",
    },
  },

  [Breakpoints.Tablet]: {
    linksWrapper: {
      display: "none",
    },
    topicsSection: {
      display: "none",
    },
    menuIcon: {
      display: "block",
    },
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      gap: 0,
      width: "calc(100% - 120px) !important",
    },
  },

  [Breakpoints.Mobile]: {
    headerWrapper: {
      width: "calc(100% - 40px) !important",
    },
  },
}));
