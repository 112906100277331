import React, { useEffect } from "react";
import { Flex } from "reusableComponents/Flex/Flex";
import close from "assets/Icons/closeIcon.svg";
import { audioPlayerUseStyles } from "pages/Home/components/LatestNews/LatestNewsItem/styles";
import WaveForm from "reusableComponents/WaveForm/WaveForm";

const AudioPlayer = ({
  playerOptions,
  setPlayerOptions,
  currentAudioInstance,
}: {
  playerOptions: any;
  setPlayerOptions: any;
  currentAudioInstance: React.MutableRefObject<any>;
}) => {
  const styles = audioPlayerUseStyles();

  useEffect(() => {
    if (playerOptions.isPlaying && currentAudioInstance.current) {
      currentAudioInstance.current.play();
    }
  }, [playerOptions.isPlaying, currentAudioInstance]);

  return (
    <Flex
      className={styles.audioPlayerWrapper}
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <Flex
        className={styles.audioPlayerContainer}
        direction="column"
        gap="12px"
      >
        <div
          className={styles.closeIcon}
          onClick={() =>
            setPlayerOptions({
              ...playerOptions,
              isOpen: false,
              isPlaying: false,
            })
          }
        >
          <img src={close} alt="close" />
        </div>
        <span className={styles.title}>{playerOptions?.title}</span>
        <WaveForm
          autoPlay
          audio={playerOptions?.audio}
          listeningDuration={playerOptions.listeningDuration}
        />
      </Flex>
    </Flex>
  );
};

export default AudioPlayer;
