import { LazyExoticComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "constants/routes";

const { NOT_FOUND } = ROUTES;

type RouteConfig = {
  path: string;
  component?: LazyExoticComponent<any> | React.ComponentType<any>;
};

interface SwitchRouterProps {
  routes: RouteConfig[];
}

const SwitchRouter: React.FC<SwitchRouterProps> = ({ routes }) => {
  return (
    <Routes>
      {routes.map(({ path, component: Component }: any, index) => (
        <Route key={index} path={path} element={<Component />} />
      ))}
      <Route path="*" element={<Navigate to={NOT_FOUND} />} />
    </Routes>
  );
};

export default SwitchRouter;
