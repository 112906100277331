import { ROUTES } from "constants/routes";

const { HOME, ABOUT_PROJECT, TOPICS } = ROUTES;

interface HeaderLink {
  name: string;
  path: string;
}

export const headerLinks: HeaderLink[] = [
  {
    name: "Topics",
    path: TOPICS,
  },
  {
    name: "Home",
    path: HOME,
  },
  {
    name: "About the project",
    path: ABOUT_PROJECT,
  },
];
