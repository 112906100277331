import { Breakpoints } from "constants/styles.constants";
import { createUseStyles } from "react-jss";

export const footerUseStyles = createUseStyles(() => ({
  wrapper: {
    margin: "0 auto",
    padding: "40px 0",
    width: "calc(100% - 140px)",
    maxWidth: "1472px",
  },
  divider: {
    width: "100%",
    height: "1px",
    background: "#DCE0E4",
  },
  privacyPolicyLink: {
    fontSize: "16px",
    color: "#171D29",
    textDecoration: "none",
    height: "20px",
    fontWeight: 400,

    "&:hover": {
      borderBottom: "1px solid #171D29",
      fontWeight: 500,
    },
  },
  termsPrivacyContainer: {},
  footerContainer: {
    "&>span": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#858C9B",
    },
  },
  location: {},

  //responsiveness

  [Breakpoints.Tablet]: {
    wrapper: {
      width: "calc(100% - 120px)",
    },
  },

  [Breakpoints.Mobile]: {
    wrapper: {
      width: "calc(100% - 40px)",
    },
    termsPrivacyContainer: {
      flexDirection: "column",
      gap: "12px",
    },
    footerContainer: {
      flexDirection: "column",
      gap: "40px",
      "& > div ": {
        alignItems: "flex-start",
      },
    },
    location: {
      alignItems: "flex-start",
    },
  },
}));
