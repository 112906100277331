import { Breakpoints } from "constants/styles.constants";
import { createUseStyles } from "react-jss";

export const headerMobailUseStyles = createUseStyles(() => ({
  linksWrapperMobile: {
    display: "none",
    width: "100%",
    height: "calc(100vh - 98px)",
    overflow: "auto",
    background: "#fff",
    "& > :first-child": {
      paddingTop: "52px",
    },
  },

  links: {
    padding: "20px 60px 20px",
    color: "#858C9B",
    fill: "#858C9B",
    fontSize: "17px",
    borderTop: "1px solid #E6E6EB",
    width: "100%",
  },

  btnWrap: {
    width: "100%",
    gap: "20px",
    padding: "20px 60px 20px",
  },

  IsActive: {
    fontWeight: "700",
    color: "#537FC1",

    padding: "20px 60px  20px ",
    fontSize: "17px",
    borderTop: "1px solid #E6E6EB",
    width: "100%",
    "&>div>img": {
      fill: "#537FC1",
    },
  },
  topicsContainer: {
    paddingTop: "20px",
  },
  topic: {
    padding: "20px 0",
    fontSize: "17px",
    fontWeight: "500",
    color: "#858C9B",
  },
  activeTopic: {
    padding: "20px 0",
    fontSize: "17px",
    fontWeight: "600",
    color: "#537FC1",
  },

  [Breakpoints.Tablet]: {
    linksWrapperMobile: {
      display: "block",
      "& > button": {
        width: "100% !important",
      },
    },
  },
  [Breakpoints.Mobile]: {
    links: {
      padding: "20px",
    },
    IsActive: {
      padding: "20px",
    },
    btnWrap: {
      padding: "20px",
    },
  },
}));
