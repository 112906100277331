import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Flex } from "reusableComponents/Flex/Flex";
import { ROUTES } from "constants/routes";
import { headerUseStyles } from "./Header.styles";
import { Button } from "reusableComponents/Button/Button";
import GenerateMobileHeader from "./GenerateMobileHeader/GenerateMobileHeader";

import hottakeAiLogo from "assets/Icons/hottakeAiLogo.svg";
import closeMenuIcon from "assets/Icons/closMenuIcon.svg";
import openMenuIcon from "assets/Icons/openMenuIcon.svg";
import close from "assets/Icons/closeIcon.svg";
import { Topics } from "constants/onBoarding.constants";

const Header = () => {
  const styles = headerUseStyles();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("hero");
  const [isTopicsOpen, setIsTopicsOpen] = useState<boolean>(false);
  const topicsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const activeTabStyles = {
    fontWeight: "700",
    color: "#537FC1",
  };

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        topicsRef.current &&
        !e.target.closest('div[role="presentation"]') &&
        !(topicsRef.current as any).contains(e.target)
      ) {
        setIsTopicsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    //eslint-disable-next-line
  }, [topicsRef]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 50 && rect.bottom >= 50) {
          setActiveTab(section.id);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  return (
    <>
      <div
        className={styles.wrapper}
        style={{ overflow: isMenuOpen ? "hidden" : "auto" }}
      >
        <div>
          <Flex alignItems="center" className={styles.headerWrapper}>
            <div
              onClick={() => {
                navigate(ROUTES.HOME);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setIsMenuOpen(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={hottakeAiLogo} alt="hottakeAiLogo" />
            </div>

            <Flex
              alignItems="center"
              justifyContent="space-between"
              className={styles.linksWrapper}
              width="100%"
            >
              <Flex
                alignItems="center"
                gap="32px"
                className={styles.linksContainer}
              >
                <span
                  onClick={() => setIsTopicsOpen(true)}
                  style={
                    location.pathname.includes("topics") ? activeTabStyles : {}
                  }
                >
                  Topics
                </span>
                <span
                  onClick={() => navigate(ROUTES.HOME)}
                  style={
                    location.pathname === ROUTES.HOME ? activeTabStyles : {}
                  }
                >
                  Home
                </span>
                <span
                  onClick={() => navigate(ROUTES.ABOUT_PROJECT)}
                  style={
                    location.pathname === ROUTES.ABOUT_PROJECT
                      ? activeTabStyles
                      : {}
                  }
                >
                  About the project
                </span>

                <a href={ROUTES.SIGN_IN}>Sign in</a>
              </Flex>

              {!(
                activeTab === "hero" &&
                location.pathname === ROUTES.ABOUT_PROJECT
              ) && <Button link={ROUTES.SIGN_UP}>Start free trial</Button>}
            </Flex>

            <div
              className={styles.menuIcon}
              onClick={() => {
                setIsMenuOpen((prev: boolean) => !prev);
              }}
            >
              <img
                src={isMenuOpen ? closeMenuIcon : openMenuIcon}
                alt="openMenuIcon"
              />
            </div>
          </Flex>
          {isTopicsOpen && (
            <div
              style={{
                borderTop: "1px solid #E6E6EB",
                boxShadow: " 4px 4px 16px 0px rgba(228, 224, 217, 0.40)",
              }}
            >
              <div className={styles.topicsSection}>
                <Flex
                  className={styles.topicsWrapper}
                  direction="column"
                  gap="40px"
                  refValue={topicsRef}
                  width="100%"
                >
                  <Flex
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                  >
                    <span className={styles.topicsTitle}>Topics</span>
                    <div
                      onClick={() => setIsTopicsOpen(false)}
                      className={styles.closeIcon}
                    >
                      <img src={close} alt="" width={40} />
                    </div>
                  </Flex>
                  <Flex
                    gap="24px"
                    flexWrap="wrap"
                    direction="column"
                    width="100%"
                    className={styles.topicsContainer}
                  >
                    {Topics.map((topic: string) => (
                      <span
                        onClick={() => {
                          setIsTopicsOpen(false);
                          navigate(`/topics/${topic}`);
                        }}
                      >
                        {topic}
                      </span>
                    ))}
                  </Flex>
                </Flex>
              </div>
            </div>
          )}
        </div>
        {isMenuOpen && (
          <GenerateMobileHeader
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        )}
      </div>
    </>
  );
};

export default Header;
