export const Topics = [
  "Arts and Entertainment",
  "Business",
  "Environment",
  "Health",
  "Politics",
  "Science",
  "Sports",
  "Technology",
];
